$primary: #1c1c1c !default; $secondary: #f9fcff !default; $tertiary: #1d6d6d !default; $quaternary: #cb3727 !default; $header_background: #cb3727 !default; $header_primary: #ffffff !default; $highlight: #e6cb37 !default; $danger: #d05454 !default; $success: #71bd9f !default; $love: #c16ad7 !default; $selected: #d2edf3 !default; $hover: #d2edf3 !default;  $public_image_path: "https://ghdev.cnvc.org/images";  @import "common/foundation/variables"; @import "common/foundation/mixins"; .topic-above-footer-buttons-outlet.presence {
  min-height: 2.5em; // height of the avatars, prevents layout shift
  margin: var(--below-topic-margin) 0;
}

.presence-users {
  background-color: var(--secondary);
  color: var(--primary-medium);
  display: flex;
  padding: 0.5em;
  padding-left: 0;
  border-radius: var(--d-border-radius);

  span.presence-text {
    align-items: center;
    display: flex;
    flex: 0 0 auto;
    margin-left: 8px;
  }

  .presence-avatars {
    display: flex;
    overflow: hidden;
    flex-wrap: wrap;
  }

  .wave {
    display: inline-flex;
    flex: 0 0 auto;

    .dot {
      display: inline-block;
      @media (prefers-reduced-motion: no-preference) {
        animation: wave 1.8s linear infinite;
      }

      &:nth-child(2) {
        animation-delay: -1.6s;
      }

      &:nth-child(3) {
        animation-delay: -1.4s;
      }
    }
  }

  @keyframes wave {
    0%,
    60%,
    100% {
      transform: initial;
    }

    30% {
      transform: translateY(-0.2em);
    }
  }
}

.reply-to .presence-users {
  padding: unset;
}

// TMP: RTL overrides
.rtl {
  span.presence-text {
    margin-right: 8px;
  }
}

// Always hide the "Topic Presence" in the topic timeline
.topic-navigation.with-timeline .topic-navigation-bottom-outlet.presence {
  display: none;
}

// When topic progress is visible in the posts grid area and is sticky,
// adjust positioning so presence is on the same line
@media screen and (max-width: 924px) {
  body:has(.topic-navigation.with-topic-progress)
    .topic-above-footer-buttons-outlet.presence {
    margin-top: -3.2em;
    margin-right: 8em;
  }
}
